import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminGuard from '../guards/AdminGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register/:type/:token',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify/:email', element: <VerifyCode /> },
        { path: 'confirm-reset/:link', element: <ConfirmReset /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/index" replace /> },
        { path: 'index', element: <GeneralApp /> },

        { path: 'analyze', element: <Analyze1 /> },
        { path: 'feedback', element: <AdminGuard><ADMIN_Feedback /></AdminGuard> },
        { path: 'feedback/detail/:token', element: <AdminGuard><ADMIN_Feedback_DETAIL /></AdminGuard> },

        { path: 'payment/history', element: <PaymentHistory /> },
        { path: 'payment/check/:token', element: <FreeLinkCheck /> },

        // { path: 'materials', element: <Matrials /> },
        // { path: 'materials/add', element: <AddMatrials /> },
        // { path: 'materials/edit/:id', element: <EditMatrials /> },
        // { path: 'calendar', element: <Calendar /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'account/add', element: <AddAccount /> },
        { path: 'account/edit/:id', element: <EditAccount /> },

        // { path: 'contact', element: <Contact /> },
        // { path: 'contact/add', element: <AddContact /> },
        // { path: 'contact/edit/:id', element: <EditContact /> },

        // success
        // { path: 'outbox/success', element: <Success /> },

        // { path: 'nocontact', element: <NoContactList /> },
        // { path: 'credit', element: <Credits /> },

        // outbox
        // { path: 'outbox/group', element: <OutboxGroup /> },
        // { path: 'outbox/one', element: <OutboxOne /> },
        // { path: 'outbox/schedule', element: <ScheduledList /> },

        // Group SMS logs
        // { path: 'grouplogs', element: <GroupSMSLogs /> },
        // { path: 'grouplogs/details/:id', element: <GroupSMSLogDetails /> },

        // plaid
        // {
        //   path: 'plaid',
        //   children: [
        //     { element: <PlaidIndex /> },
        //   ]
        // },

        // inbox
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat /> },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> }
        //   ]
        // },

        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <Matrials /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> }
        //   ]
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'history',
          children: [
            { element: <Navigate to="/dashboard/history/index" replace /> },
            { path: 'index', element: <History /> },
            { path: 'detail/:id', element: <HistoryDetail /> },
          ]
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/new-post" replace /> },
            // { path: 'posts', element: <BlogPosts /> },
            // { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <AdminGuard><BlogNewPost /></AdminGuard> },
            { path: 'update-post/:id', element: <AdminGuard><BlogUpdatePost /></AdminGuard> },
            { path: 'list', element: <AdminGuard><BlogList /></AdminGuard> }
          ]
        },
        {
          path: 'freepremium',
          children: [
            { element: <Navigate to="/dashboard/freepremium/index" replace /> },
            { path: 'index', element: <AdminGuard><CreateAdminInviteLink /></AdminGuard> },
          ]
        },
        {
          path: 'subscription',
          children: [
            { element: <Navigate to="/dashboard/subscription/index" replace /> },
            { path: 'index', element: <AdminGuard><SubscriptionHistory /></AdminGuard> },
            { path: 'detail/:user', element: <AdminGuard><SubscriptionHistoryDetail /></AdminGuard> },
          ]
        },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace /> },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> }
        //   ]
        // },
        // { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'cookie-policy', element: <CookiePolicy /> },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
          ]
        },
        { path: 'terms-condition', element: <TermsConditions /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <ContactMain /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'analyze', element: <Analyze /> },
        { path: 'feedback', element: <AuthGuard><Feedback /></AuthGuard> },
        { path: 'feedback/list', element: <AuthGuard><FeedbackLIST /></AuthGuard> },
        { path: 'feedback/requests/:token', element: <AuthGuard><FeedbackDETAIL /></AuthGuard> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const ContactMain = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Analyze = Loadable(lazy(() => import('../pages/main/alalyze/index')));
const Analyze1 = Loadable(lazy(() => import('../pages/dashboard/alalyze/index')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/main/privacypolicy')));
const CookiePolicy = Loadable(lazy(() => import('../pages/main/cookiepolicy')));
const TermsConditions = Loadable(lazy(() => import('../pages/main/termscondition')));

const PaymentHistory = Loadable(lazy(() => import('../pages/dashboard/payment/history')));
const History = Loadable(lazy(() => import('../pages/dashboard/history')));
const Feedback = Loadable(lazy(() => import('../pages/main/feedback')));
const FeedbackLIST = Loadable(lazy(() => import('../pages/main/feedback/list')));
const FeedbackDETAIL = Loadable(lazy(() => import('../pages/main/feedback/detail')));
const ADMIN_Feedback = Loadable(lazy(() => import('../pages/dashboard/feedback')));
const ADMIN_Feedback_DETAIL = Loadable(lazy(() => import('../pages/dashboard/feedback/detail')));

// Plaid
// const PlaidIndex = Loadable(lazy(() => import('../pages/dashboard/plaid/index')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const ConfirmReset = Loadable(lazy(() => import('../pages/authentication/ConfirmReset')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// const Matrials = Loadable(lazy(() => import('../pages/dashboard/Matrial/index')));

const HistoryDetail = Loadable(lazy(() => import('../pages/dashboard/history/detail')));

// Materials
// const AddMatrials = Loadable(lazy(() => import('../pages/dashboard/Matrial/Add')));
// const EditMatrials = Loadable(lazy(() => import('../pages/dashboard/Matrial/Edit')));

// // Contact
// const Contact = Loadable(lazy(() => import('../pages/dashboard/Contact/index')));
// const AddContact = Loadable(lazy(() => import('../pages/dashboard/Contact/add/index')));
// const EditContact = Loadable(lazy(() => import('../pages/dashboard/Contact/Edit')));

// // outbox
// const OutboxGroup = Loadable(lazy(() => import('../pages/dashboard/outbox/group')));
// const OutboxOne = Loadable(lazy(() => import('../pages/dashboard/outbox/one')));
// const ScheduledList = Loadable(lazy(() => import('../pages/dashboard/outbox/scheduled')));

// // No Contact List
// const NoContactList = Loadable(lazy(() => import('../pages/dashboard/NoContactList')));

// // Credits
// const Credits = Loadable(lazy(() => import('../pages/dashboard/credits')));

// // Group SMS logs
// const GroupSMSLogs = Loadable(lazy(() => import('../pages/dashboard/bulksmslog')));
// const GroupSMSLogDetails = Loadable(lazy(() => import('../pages/dashboard/bulksmslog/details')));

// // Success
// const Success = Loadable(lazy(() => import('../pages/dashboard/component/success')));

const Accounts = Loadable(lazy(() => import('../pages/dashboard/Accouts/index')));
const AddAccount = Loadable(lazy(() => import('../pages/dashboard/Accouts/add')));
const EditAccount = Loadable(lazy(() => import('../pages/dashboard/Accouts/Edit')));

// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const BlogUpdatePost = Loadable(lazy(() => import('../pages/dashboard/BlogUpdatePost')));
const BlogList = Loadable(lazy(() => import('../components/_dashboard/blog/blogList')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const CreateAdminInviteLink = Loadable(lazy(() => import('../pages/dashboard/invite')));
const FreeLinkCheck = Loadable(lazy(() => import('../pages/dashboard/payment/check')));
const SubscriptionHistory = Loadable(lazy(() => import('../pages/dashboard/subscription/index')));
const SubscriptionHistoryDetail = Loadable(lazy(() => import('../pages/dashboard/subscription/detail')));
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));


