import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';


AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const useinfo = jwt_decode(localStorage.getItem('token'));

  if (useinfo.role !== 'admin') {
    window.location.href = "/dashboard/index";
  }
  return <>{children}</>;
}
