import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(jwt_decode(localStorage.getItem('token')));
    console.log(222, jwt_decode(localStorage.getItem('token')))
  }, [])

  const handleLogout = async () => {
    localStorage.removeItem('token');
    // eslint-disable-next-line no-restricted-globals
    window.location.reload();
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '0.975rem !important'
          }}
        >
          {/* <RouterLink to="/">
            <Logo />
          </RouterLink> */}
          <a href="https://aianalyzer.io">
            <Logo />
          </a>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {user.email === "unknown@gmail.com" &&
            <>
              <Button variant="contained" component={RouterLink} to="/dashboard/index" sx={{ mr: 2 }}>
                Sign in
              </Button>

              <Button variant="contained" component={RouterLink} to="/auth/register/index/er3fgReSnKJu">
                Sign up
              </Button>
            </>
          }

          {user.email !== "unknown@gmail.com" &&
            <>
              <Button variant="outlined" onClick={handleLogout} sx={{ mr: 2 }}>
                Logout
              </Button>
            </>
          }

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
