import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Button, Typography } from '@mui/material';
import axios from 'axios'
import { message } from 'antd'

// Initialize Firebase (replace with your Firebase project config)
const firebaseConfig = {
    apiKey: "AIzaSyAF1dDW1o2iHo6-vtVlVxoVEWwtKXy0gcQ",
    authDomain: "aianalyze.firebaseapp.com",
    projectId: "aianalyze",
    storageBucket: "aianalyze.appspot.com",
    messagingSenderId: "996282156858",
    appId: "1:996282156858:web:f37dfd6146d1a25622b203",
    measurementId: "G-M0EGTGWY4C"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const GoogleAuth = (props) => {
    const { type, token } = props;
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    const handleGoogleSignIn = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            const result = await firebase.auth().signInWithPopup(provider);
            setUser(result.user);


            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/googlesignin`, result.user)
                .then((res) => {
                    if (res.data.flag === 'success') {
                        localStorage.setItem('token', res.data.token);
                        window.location.href = '/';
                    } else {
                        message.error(res.data.error)
                    }
                })
                .catch((err) => {
                    message.error(err)
                });
        } catch (error) {
            setError(error.message);
        }
    };


    const handleGoogleSignUp = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            const result = await firebase.auth().signInWithPopup(provider);
            setUser(result.user);

            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/googlesignup`, { param: result.user, type: type, token: token })
                .then((res) => {
                    if (res.data.flag === 'success') {
                        localStorage.setItem('token', res.data.token);
                        window.location.href = '/';
                    } else {
                        message.error(res.data.error)
                    }
                })
                .catch((err) => {
                    message.error(err)
                });

        } catch (error) {
            setError(error.message);
        }
    };

    const handleSignOut = async () => {
        try {
            await firebase.auth().signOut();
            setUser(null);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {type === 0 ?
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGoogleSignIn}
                        startIcon={<img style={{ width: '30px' }} src="/static/google.png" alt="Google Icon" />} // Replace 'google-icon.png' with the path to your Google icon image
                    >
                        Login with Google
                    </Button>
                    :
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGoogleSignUp}
                        startIcon={<img style={{ width: '30px' }} src="/static/google.png" alt="Google Icon" />} // Replace 'google-icon.png' with the path to your Google icon image
                    >
                        Sign Up with Google
                    </Button>
                }
                {/* <button onClick={handleGoogleSignIn}>Sign In with Google</button>
                    <button onClick={handleGoogleSignUp}>Sign Up with Google</button> */}
                <Typography sx={{ mt: '5px', fontSize: '14px', color: 'red' }} variant="body1" component="p">{error && <p>{error}</p>}</Typography>
            </div>
        </div>
    );
};

export default GoogleAuth;
